import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import dayjs from 'dayjs';
import MenuItem from '@mui/material/MenuItem';
import { Alert, Divider } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { httpsCallable } from 'firebase/functions';
import { AlertTitle } from '@mui/lab';
import { functions } from '../../../../firebase-config';
import { watchCollection } from '../../../api/document-api/DocumentAPI';
import { CheckDateDoc, CheckPeriod, PayrollCompany } from './payroll-period-types';
import { UploadInvoiceButton } from './UploadInvoiceButton';
import { UploadLogsDataGrid } from './UploadLogsDataGrid';
import { PeriodConfig } from './PeriodConfig';
import { getCheckDateDoc } from './payroll-periods-helper';

/**
 * Gets text describing missing config docs for upcoming check date.
 * @param companies all companies
 * @param latestRegularCheckDate  most recent regular check date
 * @param checkDateDocs all check date docs
 */
const getMissingConfigsText = (
  companies: PayrollCompany[],
  latestRegularCheckDate: CheckPeriod,
  checkDateDocs: CheckDateDoc[],
) => {
  const configuredCompanies = checkDateDocs
    .filter((doc) => doc.checkDate === latestRegularCheckDate.checkDate
        && doc.periodStart === latestRegularCheckDate.periodStart
        && doc.periodEnd === latestRegularCheckDate.periodEnd)
    .map(({ fein }) => fein);
  const missingCompanies = companies.filter(({ clientId }) => !configuredCompanies.includes(clientId));
  return missingCompanies.map(({ clientName }) => clientName).join(', ');
};

/**
 * Gets all check dates and all companies.  These are used when browsing/selecting configurations.
 */
const getPeriodSelectionOptions = async () => {
  const datesRes = await httpsCallable(functions, 'getCheckDates')();
  const datesData = datesRes.data as CheckPeriod[];
  const companiesRes = await httpsCallable(functions, 'getCompanies')();
  const companiesData = companiesRes.data as PayrollCompany[];
  return { datesData, companiesData };
};

/**
 * Component that displays payroll periods information and allows user to configure values for these periods (values
 * which are later used to generate payroll invoices).
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const PayrollPeriods = function () {
  const [selectedCheckDate, setSelectedCheckDate] = useState<string>('');
  // check date (represents a given payroll period)
  const [selectedCheckPeriod, setSelectedCheckPeriod] = useState<CheckPeriod | null>(null);
  // fein/id of the company whose payroll we are looking at for the given period
  const [selectedCompanyId, setSelectedCompanyId] = useState<string>('');
  // config document for selected check date/fein
  const [selectedCheckDateDoc, setSelectedCheckDateDoc] = useState<CheckDateDoc & { pdocId: string } | null>(null);
  // all config documents for all check dates/feins
  const [checkDateDocs, setCheckDateDocs] = useState<CheckDateDoc[]>([]);
  const [checkPeriods, setCheckPeriods] = useState<CheckPeriod[]>([]);
  // Westland companies that run payroll
  const [companies, setCompanies] = useState<PayrollCompany[]>([]);
  const [loadingPeriodSelectionOptions, setLoadingPeriodSelectionOptions] = useState<boolean>(true);
  // whether the current config has already successfully uploaded an invoice in the past
  const [alreadyUploaded, setAlreadyUploaded] = useState<boolean>(true);
  // whether to disable the ability to manually upload invoice
  const [disableManualUpload, setDisableManualUpload] = useState<boolean>(false);

  const updateAlreadyUploaded = (uploaded: boolean) => setAlreadyUploaded(uploaded);
  const updateDisableManualUpload = (disable: boolean) => setDisableManualUpload(disable);

  // all the check periods for a given company (different companies can have different check periods)
  const companyCheckPeriods = checkPeriods.filter(({ clientId }) => clientId.toString() === selectedCompanyId);

  // because check dates is already sorted in descending order, this should retrieve the most recent regular ('R') run
  const latestRegularCheckPeriod = checkPeriods.find(({ runType }) => runType === 'R');

  // text describing if there are any configurations missing for any companies for the most recent regular check date
  const missingConfigsText = latestRegularCheckPeriod
    ? getMissingConfigsText(companies, latestRegularCheckPeriod, checkDateDocs)
    : 'Loading...';

  // retrieves all check dates (payroll periods) and all companies
  useEffect(() => {
    setLoadingPeriodSelectionOptions(true);
    getPeriodSelectionOptions()
      .then(({ datesData, companiesData }) => {
        setCheckPeriods(datesData);
        setCompanies(companiesData);
      })
      .catch(() => { enqueueSnackbar('Failed to load period selection options', { variant: 'error' }); })
      .finally(() => setLoadingPeriodSelectionOptions(false));
  }, []);

  // watch check date config docs collection
  useEffect(() => {
    const unsubscribe = watchCollection(() => {}, setCheckDateDocs, 'payroll/checkDate/configuration');
    return () => unsubscribe();
  }, []);

  // When selected company is changed, check date should be reset to ''.
  useEffect(() => { setSelectedCheckDate(''); }, [selectedCompanyId]);
  // When selected check date is changed, check period should be reset to null.
  useEffect(() => { setSelectedCheckPeriod(null); }, [selectedCheckDate]);

  // when check date or FEIN changes (or if logs update), get the relevant check date configuration doc (if it exists)
  useEffect(() => {
    // if the selected check period is null/reset, nullify the selected check date doc.
    if (!selectedCheckPeriod || !selectedCompanyId) {
      setSelectedCheckDateDoc(null);
      return;
    }
    // get check date doc for selected check date and FEIN (if it exists)
    getCheckDateDoc(selectedCompanyId, selectedCheckPeriod)
      .then((doc) => setSelectedCheckDateDoc(doc || null))
      .catch(() => enqueueSnackbar('Failed to get check date doc', { variant: 'error' }));
  }, [selectedCheckPeriod, checkDateDocs]);

  // list of unique check dates - used for selection in the check date selector
  const uniqueCheckDates = Array.from(new Set(companyCheckPeriods.map(({ checkDate }) => checkDate)));

  return (
    <div>
      {/* Description */}
      <div className="mt-4 text-lg">Configure manual checks and cash amount for a given payroll run.</div>
      <div className="flex flex-row mt-4 gap-x-10 items-center">
        {/* Select FEIN/Company */}
        <FormControl className="w-96">
          <InputLabel>FEIN</InputLabel>
          <Select
            value={selectedCompanyId}
            label="FEIN"
            onChange={(e) => { setSelectedCompanyId(e.target.value); }}
          >
            {companies.map(({ clientId, clientName, propertyCode }) => (
              <MenuItem value={clientId}>{`${clientName} (${clientId}) (${propertyCode})`}</MenuItem>
            ))}
            {loadingPeriodSelectionOptions && !companies.length && (<MenuItem>Loading...</MenuItem>)}
          </Select>
        </FormControl>
        {/* Select check date */}
        <FormControl className="w-44">
          <InputLabel>Check Date</InputLabel>
          <Select
            value={selectedCheckDate}
            label="Check Date"
            onChange={(e) => { setSelectedCheckDate(e.target.value); }}
          >
            {uniqueCheckDates.map((date) => <MenuItem value={date}>{date}</MenuItem>)}
            {loadingPeriodSelectionOptions && (<MenuItem disabled>Loading...</MenuItem>)}
            {!loadingPeriodSelectionOptions && !selectedCompanyId && (<MenuItem disabled>Select an FEIN</MenuItem>)}
          </Select>
        </FormControl>
        {/* Select check period given check date */}
        <FormControl className="w-64">
          <InputLabel>Check Period</InputLabel>
          <Select
            value={selectedCheckPeriod?.id}
            label="Check Period"
            onChange={({ target }) => {
              const relevantCheckPeriod = companyCheckPeriods.find(({ id }) => id === target.value);
              if (!relevantCheckPeriod) return;
              setSelectedCheckPeriod(relevantCheckPeriod);
            }}
          >
            {/* Filter company check periods by selected check date, then render an item for each period */}
            {companyCheckPeriods
              .filter(({ checkDate }) => checkDate === selectedCheckDate)
              .map(
                ({ periodStart, periodEnd, id }) => {
                  const label = `${dayjs(periodStart).format('MM/DD')} to ${dayjs(periodEnd).format('MM/DD')}`;
                  return (<MenuItem value={id}>{label}</MenuItem>);
                },
              )}
            {loadingPeriodSelectionOptions && (<MenuItem disabled>Loading...</MenuItem>)}
            {!loadingPeriodSelectionOptions
                && (!selectedCompanyId || !selectedCheckDate)
                && (<MenuItem disabled>Select an FEIN and check date</MenuItem>)}
          </Select>
        </FormControl>
        {/* Alert that displays if missing configs for next check date */}
        {missingConfigsText && latestRegularCheckPeriod && (
        <Alert severity="warning">
          <AlertTitle>{`Next regular check date is ${latestRegularCheckPeriod.checkDate}`}</AlertTitle>
          {`Missing configs for ${missingConfigsText} for ${latestRegularCheckPeriod.checkDate}`}
        </Alert>
        )}
      </div>
      {selectedCompanyId && selectedCheckPeriod && (
      <div className="flex flex-row gap-x-8 mt-8">
        {/* Config inputs */}
        <PeriodConfig
          alreadyUploaded={alreadyUploaded}
          selectedCheckPeriod={selectedCheckPeriod}
          selectedCompanyId={selectedCompanyId}
          companies={companies}
          selectedCheckDateDoc={selectedCheckDateDoc}
          setDisableManualUpload={updateDisableManualUpload}
        />
        <Divider orientation="vertical" flexItem />
        <div className="flex flex-col gap-y-4 min-h-96 w-full">
          {/* Upload logs datagrid */}
          <UploadLogsDataGrid
            selectedCheckDateDoc={selectedCheckDateDoc}
            setAlreadyUploaded={updateAlreadyUploaded}
          />
          {/* Manual invoice upload button */}
          <UploadInvoiceButton
            selectedCompanyId={selectedCompanyId}
            selectedCheckPeriod={selectedCheckPeriod}
            disableManualUpload={disableManualUpload}
          />
        </div>
      </div>
      )}
    </div>
  );
};
