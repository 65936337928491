/**
 * Represents a Westland company which runs its own payroll.
 */
export interface PayrollCompany {
  clientName: string,
  clientId: string
  propertyCode: string
}

/**
 * Represents a configuration document for a given check date/FEIN.
 */
export interface CheckDateDoc {
  manualChecks: number,
  cashAmount: number,
  fein: string
  propertyCode: string,
  checkDate: string
  feinName: string
  lastUpdatedBy: string
  manualGarnishments: ManualGarnishments
  periodStart: string,
  periodEnd: string
}

/**
 * Represents response status for a given invoice upload attempt.
 */
export const responseSeverity = {
  error: 'error',
  success: 'success',
  warning: 'warning',
  rateLimit: 'rateLimit',
} as const;

/**
 * Represents response/result data for an attempted invoice upload.
 */
export interface UploadHandlerResult {
  batchNumber?: number,
  title: string,
  severity: typeof responseSeverity[keyof typeof responseSeverity],
  message: string,
  link?: string
  postFlightFlags?: string
}

/**
 * Represents a line item within the payroll invoice.
 */
interface PayrollInvoiceLineItem {
  PropertyCode: string,
  GLCode: string,
  Notes: string,
  Amount: number
}

/**
 * Represents an aggregated paystubs code sum.
 */
export interface AggregatePayrollCodeRow {
  Code: string,
  AmountTotal: number,
  GlCategory: string,
}

/**
 * Represents an event log that describes an invoice upload attempt for a given check date/FEIN.
 */
export interface PeriodLog {
  timestamp: string
  result: UploadHandlerResult
  fein: string
  checkDate: string
  lineItems?: PayrollInvoiceLineItem[]
  paystubs?: AggregatePayrollCodeRow[]
  checkDateDocId: string
}

/**
 * Represents manual employee garnishments from a given payroll invoice.
 */
export interface ManualGarnishments {
  amount: number,
  description: string
}

/**
 * Represents a pay period.  Different run types are basically different period types.
 */
export interface CheckPeriod {
  id: string
  clientId: number,
  checkDate: string
  periodStart: string
  periodEnd: string
  runType: 'R' | 'J' | 'A'
}
