import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { IconButton } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import DownloadIcon from '@mui/icons-material/Download';
import { utils, writeFileXLSX } from 'xlsx';
import { PeriodLog, responseSeverity } from './payroll-period-types';
import { watchCollection } from '../../../api/document-api/DocumentAPI';
import { UploadLogsDataGridParams } from './payroll-periods-params';

// columns for Logs datagrid
const columns: GridColDef[] = [
  {
    field: 'timestamp',
    headerName: 'Timestamp',
    valueGetter: (value: string) => dayjs(value).format('MM-DD-YYYY hh:mm:ssa'),
    width: 200,
  },
  {
    field: 'resultStatus',
    headerName: 'Status',
    valueGetter: (value, { result }: PeriodLog) => result.severity,
    width: 100,
  },
  {
    field: 'title',
    headerName: 'Title',
    valueGetter: (value, { result }: PeriodLog) => result.title,
    width: 150,
  },
  {
    field: 'resultMsg',
    headerName: 'Detail',
    valueGetter: (value, { result }: PeriodLog) => result.message,
    flex: 1,
  },
  {
    field: 'paystubs',
    headerName: 'Download',
    width: 100,
    renderCell: ({ row }: GridRenderCellParams<PeriodLog>) => {
      /**
       * Generate an XLSX file containing both the line items generated for the invoice
       * and the relevant paystubs grouped by payroll code (if it got to the point of generating either).
       */
      const downloadFile = () => {
        // for whatever reason, either the aggregated paystubs or line items may not be in the log, in which case
        // we should display a default message
        const defaultMessage = [{ message: 'No data found' }];
        const lineItems = utils.json_to_sheet(row?.lineItems || defaultMessage);
        const aggPaystubs = utils.json_to_sheet(row?.paystubs || defaultMessage);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, lineItems, 'Invoice Line Items');
        utils.book_append_sheet(wb, aggPaystubs, 'Payroll Codes Summed');
        const filename = `${row.checkDate} ${row.fein}.xlsx`;
        writeFileXLSX(wb, filename);
      };

      return (<IconButton onClick={downloadFile}><DownloadIcon /></IconButton>);
    },
  },
  {
    field: 'link',
    headerName: 'Link (if uploaded)',
    renderCell: ({ row }: GridRenderCellParams<PeriodLog>) => {
      const { link } = row.result;
      if (!link) return null;
      return (
        <IconButton
          onClick={() => { window.open(link); }}
        >
          <LaunchIcon />
        </IconButton>
      );
    },
    width: 150,
  },
];

/**
 * Displays upload logs for current check date/FEIN.
 * @param logs relevant upload logs
 * @constructor
 */
// eslint-disable-next-line import/prefer-default-export
export const UploadLogsDataGrid = function ({ selectedCheckDateDoc, setAlreadyUploaded }: UploadLogsDataGridParams) {
  // all invoice upload logs for all check dates
  const [checkDateLogs, setCheckDateLogs] = useState<PeriodLog[]>([]);
  // relevant invoice upload logs for the given check date/fein
  const [relevantCheckDateLogs, setRelevantCheckDateLogs] = useState<PeriodLog[]>([]);

  // watch upload logs collection
  useEffect(() => {
    const unsubscribe = watchCollection(() => {}, setCheckDateLogs, 'payroll/uploadLogs/logs');
    return () => unsubscribe();
  }, []);

  // get relevant logs whenever logs, check date, or FEIN change
  useEffect(() => {
    // Clear the logs if the selected check date doc is null
    if (!selectedCheckDateDoc) {
      setRelevantCheckDateLogs([]);
      return;
    }
    // get all relevant upload logs for selected check date
    const relevantLogs = checkDateLogs.filter((log) => log.checkDateDocId === selectedCheckDateDoc.pdocId);
    // sort logs in descending order
    relevantLogs.sort((a, b) => dayjs(b.timestamp).unix() - dayjs(a.timestamp).unix());
    setRelevantCheckDateLogs(relevantLogs);
  }, [selectedCheckDateDoc, checkDateLogs]);

  // update alreadyUploaded once relevant logs are found
  useEffect(() => {
    // an invoice has uploaded successfully if it has at least one log with a 'success' status
    const alreadyUploaded = !!relevantCheckDateLogs.find(
      ({ result }) => result.severity === responseSeverity.success,
    );
    setAlreadyUploaded(alreadyUploaded);
  }, [relevantCheckDateLogs]);

  return (
    <div className="h-96">
      <DataGridPro
        columns={columns}
        rows={relevantCheckDateLogs}
        className="w-full"
        getRowHeight={() => 'auto'}
        sx={{
          '&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell': { py: '8px' },
          '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': { py: '15px' },
          '&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell': { py: '22px' },
        }}
        getRowId={(row: PeriodLog) => row.timestamp + row.fein + row.checkDate}
      />
    </div>
  );
};
