import { CheckDateDoc, CheckPeriod } from './payroll-period-types';
import { getCollectionDocs } from '../../../api/document-api/DocumentAPI';

/**
 * Retrieves check date config doc from Firestore given FEIN and check date.
 * @param clientId FEIN
 * @param checkPeriod check period data
 */
// eslint-disable-next-line import/prefer-default-export
export const getCheckDateDoc = async (clientId: string, checkPeriod: CheckPeriod) => {
  const checkDateConfigs = await getCollectionDocs(
    'payroll/checkDate/configuration',
  ) as (CheckDateDoc & { pdocId: string })[];
  const relevantDoc = checkDateConfigs.find((doc) => doc.fein === clientId
      && doc.checkDate === checkPeriod.checkDate
      && doc.periodStart === checkPeriod.periodStart
      && doc.periodEnd === checkPeriod.periodEnd);
  return relevantDoc || null;
};
